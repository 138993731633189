body {
	font-family: 'Lato', sans-serif !important;
}

#main {
	height: 100vh;
}

.surelog-btn {
  border-radius: 20px;
  border: 1px solid white;
  color:  white;
  padding: 0.375rem 2rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: 20px;
  border: 1px solid white;
  color:  white;
}

.surelog-btn ion-icon {
	display: inline-block;
	font-size: 1.1em;
	vertical-align: middle;
	cursor: pointer;
}

.surelog-btn label {
	display: inline-block;
	vertical-align: middle;
	cursor: pointer;
}

.surelog-btn:hover {
	transition: .2s;
	background-color: #3460d0;
}

.form-control {
	color:  black !important;
}

a, a:hover, a:focus, a:visited {
	text-decoration: none !important;
}

a:hover {
	color:  gray !important;
}

.right {
	float: right !important;
}

.left {
	float: left !important;
}

.small {
	font-size: small;
}

.smaller {
	font-size: smaller;
}

textarea:focus, 
textarea.form-control:focus, 
select.form-control:focus, 
input.form-control:focus, 
input[type=text]:focus, 
input[type=password]:focus, 
input[type=email]:focus, 
input[type=number]:focus, 
[type=text].form-control:focus, 
[type=password].form-control:focus, 
[type=email].form-control:focus, 
[type=tel].form-control:focus, 
[contenteditable].form-control:focus {
  box-shadow: inset 0 -1px 0 #ddd;
}

textarea, 
textarea.form-control, 
select.form-control,
ion-select,
input.form-control, 
input[type=text], 
input[type=password], 
input[type=email], 
input[type=number], 
[type=text].form-control, 
[type=password].form-control, 
[type=email].form-control, 
[type=tel].form-control, 
[contenteditable].form-control {
  font-size: .9em !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

ion-select::part(icon) {
	display: none;
}

.sort:hover {
	transition: .2s;
	color: black;
}

.pointer {
	cursor: pointer !important;
}

th {
	font-weight: normal !important;
	vertical-align: middle;
}

ion-icon {
	pointer-events: none;
}

th:first-child {
  border-top-left-radius: 10px; 
  border-bottom-left-radius: 10px;
}

th:last-child {
  border-bottom-right-radius: 10px; 
  border-top-right-radius: 10px; 
}

.data-table {
	border-collapse:separate;
	border-spacing:0 1em;
}

.active-tab {
	background-color: #001133;
	color:  white !important;
}

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #268ac8;
}

input:focus + .slider {
  box-shadow: 0 0 1px #268ac8;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 17px;
}

.slider.round:before {
  border-radius: 50%;
}

.paginate-btn {
	border: 1px solid #ccc !important;
	font-size: 1.1em !important;
}

.paginate-btn:hover, .paginate-btn.active {
	background-color: #268ac8 !important;
	color:  white !important;
}
::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.center {
	text-align: center !important;
}

.split-pane-ios.split-pane-visible>.split-pane-side,
.split-pane-md.split-pane-visible>.split-pane-side{
  max-width: 250px;
  min-width: 250px;
  border: none !important;
}
ion-menu {
  --ion-background-color: var(--ion-color-primary);
  --ion-text-color: var(--ion-color-primary-contrast);

  ion-toolbar {
    --background: var(--ion-color-primary);
  }

  ion-list {/* optional, but it needs when you use gradient as a background color.*/
    background: transparent;
  }
}